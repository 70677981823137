import React, { useContext } from "react"
import moment from "moment"
import { Link, useStaticQuery, graphql } from "gatsby"
import { Layout, Parallax, NewsletterSection } from "@components/layout"
import { PostItem, CommentSection } from "@components/blog"
import { withHtml } from "@components/logic"
import { Tags, DirectionArrows } from "@components/theme"
import { CartCtx, useActions, useContextState } from "@components/contexted"

import {
  Breadcrumbs,
  AnimatedSection,
  AnimatedLink as A,
} from "@components/shared"
import bottomIMG from "./img/code.jpg"
import icon from "./img/open-book2.png"
import coffe from "./img/coffee-cup-hot.png"
import coffe2 from "./img/coffee-cup-hotx2.png"

import {
  post__wrapper,
  post__header,
  post__content,
  post__wave,
  post__icon,
  icon__wrap,
  post__bottomimg,
  post__coffe,
  post__coffe__buttons,
  post__coffe__buttons__btn,
} from "./styles/post.module.scss"

const reduceNodes = (withNodes) => {
  const { nodes } = withNodes
  return nodes.reduce((acc, curr) => {
    if (acc.length !== 0) {
      return `${acc}, ${curr.name}`
    }
    return curr.name
  }, "")
}

const cleanPrice = (price) => {
  return parseFloat(price.replace(/[$,]+/g, ""))
}

const Title = withHtml("h1")
const Content = withHtml("article")
const linkModifier = ({ slug }) => `/blog/${slug}/`

const Post = ({ pageContext }) => {
  const { page, nextPost, prevPost, products } = pageContext
  const { categories, tags } = page
  const [mainCategory] = categories.nodes
  const coffeProduct = products.find((el) => el.productId == 374)

  const { dispatch } = useContext(CartCtx)
  const Wave = () => {
    return (
      <div className={post__wave}>
        <svg viewBox="0 0 1200 600">
          <path
            d="M 10 380 Q 60 600 420 520 Q 895 375 1080 445 Q 1190 480 1200 595 L 0 600 A 50 50 0 1 1 0 320 "
            transform="translate(0 0)"
            fill="#fff"
          />
        </svg>
      </div>
    )
  }

  const Icon = () => {
    return (
      <div className={post__icon}>
        <img src={icon} alt="" />
      </div>
    )
  }

  return (
    <Layout
      seo={pageContext.page.seo}
      siteMetaData={pageContext.page.siteMetaData}
    >
      <div className={post__wrapper}>
        <Parallax height={500} image={page.featuredImage}>
          <Title className={post__header}>{page.title}</Title>
          <h5>Data: {moment(page.date).format("DD / MM / YYYY")}</h5>
          <Wave />
        </Parallax>
        <AnimatedSection>
          <Icon className={icon__wrap} />
        </AnimatedSection>
        <Breadcrumbs
          elements={[
            {
              label: "Blog",
              url: `/blog/`,
            },
            {
              label: mainCategory.name,
              url: `/blog/categories/${mainCategory.slug}`,
            },
            {
              label: page.title,
              url: `/blog/${page.slug}/`,
            },
          ]}
        />

        <Tags tags={tags.nodes} tagsUrl={"/tags"} />

        <Content className={post__content}>{page.content}</Content>

      

        <div className={post__coffe}>
          <h4>
            Jeśli czegoś konkretnego się dowiedziałeś z tego posta, to postaw mi
            kawę, która zasili kolejne posty z materiałami dla programistów
          </h4>
          <div className={post__coffe__buttons}>
            <A
              onClick={() => {
                dispatch({
                  type: "addToCart",
                  payload: {
                    value: cleanPrice(coffeProduct.regularPrice),
                    regularValue: cleanPrice(coffeProduct.regularPrice),
                    quantity: 1,
                    product_id: coffeProduct.productId,
                    variation_id: null,
                    variant: null,
                    sale: false,
                    price: cleanPrice(coffeProduct.regularPrice),
                    regularPrice: cleanPrice(coffeProduct.regularPrice),
                    unit: "PLN",
                    name: coffeProduct.name,
                    slug: coffeProduct.slug,
                    image: coffeProduct.image,
                  },
                })
              }}
              to={"/shop/checkout"}
            >
              <a className={post__coffe__buttons__btn}>
                <img src={coffe}></img>
              </a>
            </A>
            <A
              onClick={() => {
                dispatch({
                  type: "addToCart",
                  payload: {
                    value: cleanPrice(coffeProduct.regularPrice),
                    regularValue: cleanPrice(coffeProduct.regularPrice),
                    quantity: 1,
                    product_id: coffeProduct.productId,
                    variation_id: null,
                    variant: null,
                    sale: false,
                    price: cleanPrice(coffeProduct.regularPrice),
                    regularPrice: cleanPrice(coffeProduct.regularPrice),
                    unit: "PLN",
                    name: coffeProduct.name,
                    slug: coffeProduct.slug,
                    image: coffeProduct.image,
                  },
                })
                dispatch({
                  type: "changeProductQty",
                  payload: {
                    product_id: coffeProduct.productId,
                    quantity: 2,
                  },
                })
              }}
              to={"/shop/checkout"}
            >
              <a className={post__coffe__buttons__btn}>
                <img src={coffe2}></img>
              </a>
            </A>
          </div>
        </div>
        <DirectionArrows
          prevItem={prevPost}
          nextItem={nextPost}
          linkModifier={linkModifier}
        />

        <CommentSection post={page} />
        {/*

			<h4>prevPost </h4>

			<PostItem {...prevPost}/>
			<h4>nextPost</h4>
			<PostItem {...nextPost}/>
 
			<NewsletterSection />
			*/}
      </div>
    </Layout>
  )
}

export default Post
